var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment"},[((_vm.cross_sold_product_group && _vm.has_cross_store_payouts_enabled) || !_vm.cross_sold_product_group)?_c('div',{staticClass:"payment__errors"},[(!_vm.has_user_shipping_option && _vm.wait_for_shipping_option(_vm.selected_group))?_c('p',[_vm._v(" "+_vm._s(_vm.translations.you_need_to_choose_shipping_method || "You need to choose a shipping method.")+" ")]):_vm._e(),(_vm.countries_dont_match)?_c('p',[_vm._v(" "+_vm._s(_vm.translations.country_doesnt_match_method || "Selected country doesnt match chosen shipping country.")+" ")]):_vm._e()]):_vm._e(),_c('transition',{attrs:{"name":"slide"}},[(_vm.show_form)?_c('div',[_c('user_form',{on:{"cancel_payment_method":function($event){_vm.show_form = false},"user_data_updated":_vm.update_user_data,"data_valid":valid => _vm.user_data_valid = valid}}),_c('button',{staticClass:"button button--wide button--full-width",attrs:{"disabled":!_vm.user_data_valid},on:{"click":function($event){return _vm.payment_authorization({}, null, false)}}},[_vm._v(_vm._s(_vm.translations.finish_order || _vm.$translate("finish_order") || "Finish order"))])],1):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.show_form),expression:"!show_form"}]},[(!_vm.show_payment_buttons && !_vm.show_paypal_message)?_c('div',{staticClass:"payment__disabled"}):_vm._e(),(!_vm.is_admin_imported && _vm.project_config.use_bank_transfer && !_vm.cross_sold_product_group)?_c('div',{staticClass:"payment__other-payment-method",on:{"click":() => {
      _vm.payment_method = _vm.payment_methods.bank_transfer
      _vm.show_form = true
    }}},[_vm._v(" "+_vm._s(_vm.translations.pay_via_bank_transfer || "Pay via bank transfer")+" ")]):_vm._e(),(!_vm.is_admin_imported && _vm.project_config.use_cash_on_pickup && !_vm.cross_sold_product_group)?_c('div',{staticClass:"payment__other-payment-method",class:{ 'payment__other-payment-method--disabled': !_vm.shipping_option || !_vm.shipping_option.enable_on_pickup_payment },on:{"click":() => {
        if (_vm.shipping_option && _vm.shipping_option.enable_on_pickup_payment) {
          _vm.payment_method = _vm.payment_methods.on_pickup
          _vm.show_form = true
        }
      }}},[_vm._v(" "+_vm._s(_vm.translations.pay_via_cash || "Pay via cash/card on pickup")+" ")]):_vm._e(),(_vm.use_shopify_data)?_c('div',{staticClass:"payment__shopify-button button button--green",on:{"click":_vm.open_shopify_checkout_window}},[_vm._v(_vm._s(_vm.translations.shopify_buy_button || _vm.$translate("basket.shopify_buy_button")))]):(!_vm.is_admin_imported)?_c('div',{staticClass:"payment__paypal"},[((_vm.cross_sold_product_group && _vm.has_cross_store_payouts_enabled) || !_vm.cross_sold_product_group)?_c('div',{ref:"paypal_buttons",class:{ 'payment__buttons' : !_vm.show_payment_buttons },attrs:{"id":"paypal-button-container"}}):_vm._e(),(_vm.loading_paypal_buttons)?_c('div',{staticClass:"payment__loading"},[_c('Loader')],1):_vm._e(),(_vm.show_paypal_message || !_vm.has_cross_store_payouts_enabled)?_c('div',[(_vm.cross_sold_product_group)?_c('div',[_vm._v(" "+_vm._s(_vm.translations.products_from_cross_store_are_unavailable)+" ")]):(_vm.is_marketplace_environment)?_c('div',[_vm._v(" "+_vm._s(_vm.$translate("basket.paypal_not_connected"))+" "),(_vm.selected_group[0].project_domain)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$translate(
              'basket.contact_message',
              { link: `<a href='https://${_vm.selected_group[0].project_domain}/contact-us' target='_blank'>HERE</a>`
            }))}}):_vm._e()]):(!_vm.project_config.use_cash_on_pickup && !_vm.project_config.use_bank_transfer)?_c('div',[_vm._v(" Owner of this website has not connected a payment gateway yet, if you wish to finish your checkout process, please contact them "),_c('router-link',{staticClass:"payment__link",attrs:{"to":"/contact-us"}},[_vm._v("HERE")]),_vm._v(". ")],1):_vm._e()]):_vm._e()]):_vm._e(),(_vm.show_paypal_payment_image)?_c('img',{attrs:{"src":"/static/icons/secure_checkout.png","alt":"Secure card payments icon"}}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }