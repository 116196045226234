import round_to_two_decimals from "./round_to_two_decimals"
import update_with_percentage from "./update_with_percentage"

export default (price, maximum_shipping_discount, rate_discount) => {
  const discount_amount = price - update_with_percentage(price, rate_discount)
  const apply_max_price_limit = maximum_shipping_discount && maximum_shipping_discount < discount_amount

  return {
    price: round_to_two_decimals(price - (
      apply_max_price_limit ? maximum_shipping_discount : discount_amount
    )),
    max_price_reached: apply_max_price_limit
  }
}
