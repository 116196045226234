import { get_shipping_rates } from "../constants/endpoints";

export default async (address, basket_items, currency) => {
  const { data } = await get_shipping_rates({
    ...address,
    products: basket_items,
    currency
  })

  return data.sort(({ amount }, { amount: amount_b }) => amount - amount_b)
}