<template>
  <div
    class="user-form"
    :class="{ 'load-animation-class': visible }"
    v-observe-visibility="(is_visible) => visible = is_visible"
  >
    <button
      class="button button--reverse button--naked"
      @click="$emit('cancel_payment_method')"
    >{{ translations.cancel_payment_method || $translate("cancel_payment_method") || "Cancel payment method" }}</button>
    <div class="user-form__shipping">
      <input
        type="text"
        id="fname"
        label="First name input"
        v-model="shipping.first_name"
        class="store-input"
        :placeholder="translations.first_name"
      >
      <input
        type="text"
        label="Last name input"
        id="lname"
        v-model="shipping.last_name"
        class="store-input"
        :placeholder="translations.last_name"
      >
      <input
        type="text"
        label="Company name input"
        id="company"
        v-model="shipping.company"
        class="store-input"
        :placeholder="translations.company_name || 'Company'"
      >
      <input
        type="text"
        label="Address first line input"
        id="address-line1"
        v-model="shipping.address1"
        class="store-input"
        :placeholder="translations.address_first"
      >
      <input
        type="text"
        id="address-line2"
        label="Additional address information"
        v-model="shipping.address2"
        class="store-input"
        :placeholder="translations.address_second"
      >
      <input
        type="number"
        id="postal-code"
        label="Zip number input"
        v-model="shipping.zip"
        class="store-input"
        :placeholder="translations.zip"
      >
      <input
        type="text"
        id="city"
        label="City name input"
        v-model="shipping.city"
        class="store-input"
        :placeholder="translations.city"
      >
      <input
        type="text"
        label="State"
        id="state"
        v-model="shipping.state"
        class="store-input"
        :placeholder="translations.state"
      >
      <div
        class="user-form__multiselect"
        v-click-outside="() => open_country_options_shipping = false"
      >
        <p
          @click="open_country_options_shipping = !open_country_options_shipping"
          :class="{
            'user-form__multiselect--unchosen': shipping.country.name === ''
          }"
        >
          {{ shipping.country.name || translations.country }}
        </p>
        <div
          v-if="open_country_options_shipping"
        >
          <p
            v-for="country in country_options"
            :key="`country-selector-${country.name}`"
            @click="() => {
              shipping.country = country
              open_country_options_shipping = false
            }"
            :class="{
              'user-form__multiselect--selected' : country.name === shipping.country
            }"
          >
            {{ country.name }}
          </p>
        </div>
      </div>
      <input
        type="email"
        id="email"
        label="Email input"
        v-model="shipping.email"
        class="store-input"
        :placeholder="translations.email"
      >
      <input
        type="tel"
        id="phone"
        label="Telephone input"
        v-model="shipping.phone"
        class="store-input last_input"
        :placeholder="translations.phone"
      >
    </div>
    <div class="user-form__shipping_same">
      {{
        translations.same_as_shipping
      }}
      <div class="store-checkbox">
        <input
          type="checkbox"
          id="checkbox"
          name="check"
          label="Is shipping same as billing" 
          v-model="shipping_same_as_billing"
        />
        <label for="checkbox" />
      </div>
    </div>
    <div
      v-if="!shipping_same_as_billing"
      class="user-form__billing"
    >
      <input
        type="text"
        id="fname"
        label="First name input"
        v-model="billing_data.first_name"
        class="store-input"
        :placeholder="translations.first_name"
      >
      <input
        type="text"
        id="lname"
        label="First name input"
        v-model="billing_data.last_name"
        class="store-input"
        :placeholder="translations.last_name"
      >
      <input
        type="text"
        id="company"
        label="Company name input"
        v-model="billing_data.company"
        class="store-input"
        :placeholder="translations.company_name"
      >
      <input
        type="text"
        id="address-line1"
        label="Address first line input"
        v-model="billing_data.address1"
        class="store-input"
        :placeholder="translations.address_first"
      >
      <input
        type="text"
        id="address-line2"
        label="Additional address information"
        v-model="billing_data.address2"
        class="store-input"
        :placeholder="translations.address_second"
      >
      <input
        type="number"
        id="postal-code"
        label="Zip number input"
        v-model="billing_data.zip"
        class="store-input"
        :placeholder="translations.zip"
      >
      <input
        type="text"
        id="city"
        label="City name input"
        v-model="billing_data.city"
        class="store-input"
        :placeholder="translations.city"
      >
      <input
        type="text"
        label="State"
        id="state"
        v-model="billing_data.state"
        class="store-input"
        :placeholder="translations.state"
      >
      <div
        class="user-form__multiselect"
        v-click-outside="() => open_country_options_billing = false"
      >
        <p
          @click="open_country_options_billing = !open_country_options_billing"
          :class="{
            'user-form__multiselect--unchosen': billing_data.country.name === ''
          }"
        >
          {{ billing_data.country.name || translations.country }}
        </p>
        <div
          v-if="open_country_options_billing"
        >
          <p
            v-for="country in countries"
            :key="`country-selector-${country.name}`"
            @click="() => {
              billing_data.country = country
              open_country_options_billing = false
            }"
            :class="{
              'user-form__multiselect--selected' : country.name === billing_data.country
            }"
          >
            {{ country.name }}
          </p>
        </div>
      </div>
      <input
        type="email"
        id="email"
        label="Email input"
        v-model="billing_data.email"
        class="store-input"
        :placeholder="translations.email"
      >
      <input
        type="tel"
        id="tel"
        label="Telephone input"
        v-model="billing_data.phone"
        class="store-input last_input"
        :placeholder="translations.phone"
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import countries from "../../../../../data/countries"
import { CLIENT_STORE } from "../../../../Client/constants/other"
import { SHARED_STORE } from "../../../constants/other"
import get_country_by_code from "../../../methods/get_country_by_code"

export default {
  data() {
    return {
      shipping: {
        first_name: "",
        last_name: "",
        address1: "",
        address2: "",
        zip: "",
        city: "",
        state: "",
        country: {},
        phone: "",
        email: "",
        company: ""
      },
      billing_data: {
        first_name: "",
        last_name: "",
        address1: "",
        address2: "",
        zip: "",
        city: "",
        state: "",
        country: {},
        phone: "",
        email: "",
        company: ""
      },
      inputs: [
        "first_name",
        "last_name",
        "address1",
        "zip",
        "city",
        "country",
        "phone",
        "email",
      ],
      show_shipable_products: false,
      open_country_options_shipping: false,
      open_country_options_billing: false,
      visible: false,
      shipping_same_as_billing: true,
      countries
    };
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "translations",
      "shipping_option",
      "shipping_options"
    ]),
    ...mapState(SHARED_STORE, [
      "basket_items"
    ]),
    validation() {
      return !this.inputs.some(key =>
        typeof this.shipping[key] === "object" ?
          Boolean(!this.shipping[key]?.code) : this.shipping[key] === ""
      )
    },
    items_shipable_only_to_USA() {
      return this.basket_items.filter(item => item.can_only_be_shipped_to_USA)
    },
    country_options() {
      return this.shipping_options.settings?.allowed_countries || countries
    }
  },
  watch: {
    shipping: {
      handler() {
        this.submit_form();
      },
      deep: true
    },
    billing_data: {
      handler() {
        this.submit_form();
      }
    },
    validation(val) {
      this.$emit("data_valid", val)
    }
  },
  mounted() {
    this.shipping.country = get_country_by_code(this.shipping_option.country_code)
  },
  methods: {
    submit_form() {
      if (this.validation) {
        const data = {
          ...this.shipping,
          billing_data: this.shipping_same_as_billing ? this.shipping : this.billing_data
        }

        this.$emit("user_data_updated", data);
      }
    },
  },
};
</script>

<style lang="scss">
@use "../../../../../styles/_global-constants" as *;
@use "../../../../../styles/store_checkbox";
@use "../../../../../styles/store_input";

.user-form {
  width: 100%;
  max-height: fit-content !important;
  margin: 10px 0;
  border: none !important;
  overflow: auto;
  white-space: normal;
  text-overflow: ellipsis;
  box-sizing: border-box;

  &__billing {
    white-space: normal;
  }

  &__shipping {
    white-space: normal;

    &_same {
      margin-bottom: 20px;
    }
  }

  &__shipable-products {
    position: absolute;
    bottom: calc(100% + 15px);
    max-height: fit-content !important;
    left: 0;
    width: 100%;
    padding: 5px 10px;
    line-height: 30px;
    color: $pure-white;
    font-size: 13px;
    background-color: var(--background_heavy);
    opacity: 1;
    z-index: 2;
    border: none !important;
    text-align: center;
    transition: $default-transition;
    overflow: visible !important;
    box-sizing: border-box;
    animation: show-message 300ms ease-out;

    span {
      display: block;
      margin-top: 5px;
      text-align: left;
      font-weight: bold;
      line-height: normal;
    }

    &::after {
      position: absolute;
      top: calc(100% - 5px);
      left: calc(50% - 5px);
      width: 10px;
      height: 10px;
      background-color: var(--background_heavy);
      content: "";
      transform: rotate(45deg);
    }
  }

  &__multiselect {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 5px;

    p {
      line-height: 30px;
      font-size: 14px;
      border: none;
      color: var(--font_heavy);
      background-color: $pure-white;
      border-bottom: 1px solid var(--font_heavy);
      border-radius: 0;
      outline-offset: unset;
      outline: unset;
      margin: 0;
      padding: 5px;
      text-align: center;
      cursor: pointer;
      transition: .2s linear;

      &:hover {
        background-color: var(--font_heavy);
        color: var(--font_light);
      }
    }

    div {
      max-height: 200px;
      overflow: auto;
      border: 1px solid var(--font_heavy);
      border-top: 0;
    }

    &--unchosen {
      font-family: "PT Sans", sans-serif;
      color: $shadow-color !important;

      &:hover {
        background-color: var(--font_heavy);
        color: var(--font_light) !important;
      }
    }

    &--selected {
      background-color: var(--font_heavy) !important;
      color: var(--font_light) !important;
      filter: grayscale(30%);
    }

    &--clickable {
      p {
        cursor: pointer;
      }
    }

    .select .show {
      width: 100%;
    }
  }

  .button {
    padding: 0 20px;
    margin-bottom: 30px;
  }

  .store-input {
    display: inline-block;
    width: calc(50% - 5px);
    margin-right: 10px;

    &:nth-child(even) {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 10px;
      margin-right: 0;
    }
  }
}

@keyframes show-message {
  from {
    bottom: 100%;
    opacity: 0;
  }
  to {
    bottom: calc(100% + 15px);
    opacity: 1;
  }
}
</style>
